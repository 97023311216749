.div1 {
    width: 187px;
    position: relative;
    font-size: var(--button-regular-15-uppercase-size);
    text-transform: uppercase;
    font-family: var(--h2-bold-28-uppercase);
    color: black;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }
.item-line {
  flex-wrap: wrap;
  padding: 0 var(--padding-31xl);
  gap: var(--gap-mini);
}
.h21,
.head1,
.item-line,
.title-h21 {
  align-self: stretch;
  display: flex;
}

.item-icon,
.item-icon1,
.item-icon2 {
  align-self: stretch;
  width: 388px;
  position: relative;
  max-height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 569px;
}

.circle-element-icon,
.circle-element-icon1,
.circle-element-icon2 {
    margin: 5px;
    object-fit: cover;
    border-radius: 50%;
  height: 80px;
  width: 80px;
  position: relative;
  min-height: 80px;
}

.circle-elements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--gap-3xs);

}

.block-elements,
.button-block,
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-container {
  cursor: pointer;
  border: 1px solid var(--typography-black);
  padding: var(--padding-mini) 0;
  background-color: transparent;
  width: 290px;
  height: 50px;
  box-sizing: border-box;
  flex-direction: row;
  margin: 10px;
}
.button-container:hover {
  background-color: rgba(71, 71, 74, 0.09);
  border: 1px solid #47474a;
  box-sizing: border-box;
}
.block-elements,
.button-block {
  align-self: stretch;
}
.block-elements {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
  max-width: 100%;
}
.button-block {
    width: 388px;
    /* background-color: var(--background-additional); */
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
  }
.item-line-button-block,
.n-a,
.weekly-preview-head {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.item-line-button-block {
  width: 1600px;
  flex: 1;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-10xs) 0 0;
  gap: var(--gap-mini);
}
.n-a,
.weekly-preview-head {
  align-self: stretch;
}
.weekly-preview-head {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-81xl) 0 0;
  gap: var(--gap-11xl);
}
.n-a {
  height: 828px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-81xl);
  text-align: left;
  font-size: var(--h2-bold-28-uppercase-size);
  color: var(--typography-black);
  font-family: var(--h2-bold-28-uppercase);
}





.head-parent,
.head2,
.item-line1{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-line1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-31xl);
  gap: var(--gap-mini);
}
.head-parent,
.px-main-page {
  flex-direction: column;
}

@media screen and (max-width: 1600px) {
  
  .header-container1 {
    gap: var(--gap-249xl);
  }
  .catalogs {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(397px, 689px));
  }
}
@media screen and (max-width: 1400px) {
    
  
  .search-field,
  .icon-search {
    visibility: hidden;
   
  }
}
@media screen and (max-width: 1050px) {
  .search-field,
  .icon-search {
    visibility: hidden;
   
  }
  .header-container {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  .spring-collection {
    font-size: 51px;
    line-height: 56px;
  }
  .item-line,
  .n-a {
    box-sizing: border-box;
  }
  .item-line {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
  }
  .n-a {
    padding-bottom: var(--padding-46xl);
  }
  .head2,
  .item-line1 {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
 
  .logo-menu-navigation {
    visibility: hidden;
    gap: var(--gap-31xl);
  }
  /* .input-group, */
  .search-field,
  .icon-search {
    visibility: hidden;
   
  }
 
  .header-container1 {
  
    gap: var(--gap-249xl);
    padding-top: 197px;
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .catalogs {
    grid-template-columns: minmax(397px, 1fr);
  }
  .weekly-preview-head {
    padding-top: var(--padding-46xl);
    box-sizing: border-box;
  }
  .button-block {
    width: 100%;
  }

  .circle-elements {
    padding: 0 var(--padding-mini); 
    gap: var(--gap-mini); 
  }

  .button-container {
    width: calc(100% - 20px);
    margin: 10px; 
  }
}
@media screen and (max-width: 450px) {
  
 
        /* .div1 {
         
            font-size: 9px !important; 
        } */
   
        .button-block {
          width: 100%; /* Ширина 100% на мобильных устройствах */
        }
      
        .circle-elements {
          padding: 0 var(--padding-mini);
          gap: var(--gap-mini);
        }
      
        .button-container {
          width: 100%; 
          /* height: auto; Высота автоматическая на мобильных устройствах */
          margin: 10px;
        }
  .hd-brand {
    font-size: var(--font-size-base);
  }
  .header-container1 {
    gap: var(--gap-249xl);
  }
  .h2 {
    font-size: var(--font-size-3xl);
  }
  .div1 {
    font-size: var(--font-size-base);
  }
  .head {
    flex-wrap: wrap;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .h21 {
    font-size: var(--font-size-3xl);
  }
  .head1 {
    gap: var(--gap-11xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .n-a {
    padding-bottom: 42px;
    box-sizing: border-box;
  }
  .logo-parent {
    padding-left: var(--padding-6xl);}}