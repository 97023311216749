.image-container1,
.shopping-assistant {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.image-container1 {
  flex: 1;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 29px;
}
.shopping-assistant {
  align-self: stretch;
  padding: 0 0 var(--padding-9xl) var(--padding-11xs);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-100);
  font-family: var(--desktop-hyperlink-medium-15);
}
@media screen and (max-width: 1200px) {
  .image-container1 {
    flex-wrap: wrap;
  }
}
