
#rowmargin50
{
    margin-top:50px;
}
#photocolumnmob
{
   display:none;
}
#sizemodal
{
    width: 400px;
}
@media screen and (max-width: 1125px) {
    #rowmargin50
{
    margin:0px;
}
#sizemodal
{
    width: 300px;
}

 #photocolumn
 {
    display:none;
 }
 #photocolumnmob
 {
    margin-top: 25px;
    display:block;
 }
    
  }