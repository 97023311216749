.child24 {
  height: 90px;
  width: 447px;
  position: relative;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-darkgray-100);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.camera-3-icon,
.div153 {
  position: relative;
  z-index: 1;
}
.camera-3-icon {
  margin-left: 10px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}
.div153 {
  flex: 1;
  line-height: 18.13px;
  display: inline-block;
  min-width: 214px;
  min-height: 40px;
  max-width: 100%;
  
}
.div152 {
  height: 70px;
  flex: 0.9716;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-darkgray-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: var(--gap-5xl);
  min-width: 335px;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-100);
  font-family: var(--desktop-hyperlink-medium-15);
}
@media screen and (max-width: 450px) {
  .div152 {
    flex-wrap: wrap;
    flex: 1;
    margin: 5px;
  }
}
