.h25title {
   
  
    height: 102px;
    position: relative;
    font-size: var(--desktop-h2-bold-28-uppercase-size);

    text-transform: uppercase;
    font-weight: 700;
    font-family: inherit;
    color: var(--color-black);
    justify-content: center;
  }
.di153,
.h211 {
  position: relative;
  font-weight: 500;
}
.h211 {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  font-size: 24px;
  margin-bottom: 30px;
}
.di153 {
  font-size: var(--desktop-hyperlink-medium-15-size);
  color: var(--color-gray-100);
  text-decoration: underline;
  font-weight: bold;
}
.form-control {
    border-radius: 0%;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color:black;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border: none;
    border-radius: 0%;
}
.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color:var(--color-darkgray-100);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.nav-link:hover {
    color:black;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border: none;
    border-radius: 0%;
}
.form-select-lg {
   
    border-radius: 0%;
    font-size: 1rem;
}
.form-control-lg {
    border-radius: 0%;
    font-size: 1rem;
}
.MDBCol-border {
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding: .375rem .75rem;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  
  /* добавляем отступ вокруг содержимого MDBCol */
  /* Другие стили, если необходимо */
}
#enterphone:focus
{
  outline: none; 
  border: none; 
}
#enterphone {
  border: none; /* Убираем рамку */
  /* Другие стили, если необходимо */
}
.custom-select-lg {
   
  
    padding-bottom: .5rem;
    font-size: 1rem;
    border-radius: 0%;
   
  }
  .quantity-container {
    border: 1px solid #ccc; 
    
  }
  .col-md-7 {
    border-right: 1px solid #ccc; 
    padding-right: 70px; 
}
.margin-acc
{
  padding-left: 55px;
}
.col-md-5 {
padding-left: 35px;
}
.showtotal
{
 display: none;
}

  @media screen and (max-width: 1125px) {
  
   .h211

   {
    display: block;
    margin-top: 30px;
   }
    .h25title {
        font-size: var(  --body-regular-15-size);
        height: 25px;
    }
    .di153{
        font-size: 9px;
    }
    .col-md-7 {
      border-right:none;
      padding-right: 0px; 
  }
  .col-md-7,   
  .col-md-5 {
  padding-left: 10px;
  padding-right: 10px; 

  }
  }
  @media screen and (max-width: 750px){
    .margin-acc
    {
      padding-left: 0%;
    }
    .showtotal
    {
     display: block;
    }
    #totalbtn
    {
     display: none;
    }
  }