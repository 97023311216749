
.some {
  margin-top: 10px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  
  .something {
    margin: 2px;
  }

 
