
  .column-hide{
    display: block;
  }
  
  .div35 {
    position: relative;
  }
  .div35 {
    font-weight: 500;
    margin-left: -200px;
    align-self: stretch;
  }
  .materials-list {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0 6px;
  }
  .color-picker,
  .materials-list1,
  .size-picker {
    margin-right: 50px;
    align-self: stretch;
    display: flex;
    flex-direction: row;
  }
  
  .color-picker,
  .size-picker {
    align-items: flex-start;
  }
  .size-picker {
    
    justify-content: flex-start;
    gap: 0 30px;
  }
  .color-picker {
    height: 12px;
    justify-content: flex-end;
    padding: 0 var(--padding-11xs);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--desktop-hyperlink-medium-15-size);
    color: var(--color-gray-100);
  }

  .div36{
    margin-left: -120px;
    position: relative;
    align-self: stretch;
  }


  .h23 {
    margin-bottom: 50px;
    font-size: inherit;
    font-family: inherit;
  }


  .div37,
  .h23 {
 
    opacity: 0.5;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
  }

  .div53 {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    font-weight: 500;
  }





  .child,
.div15 {
  position: absolute;
  display: none;
}
.child {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.materials-list1 {
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
}
.div15 {
  top: 22.86%;
  left: 25.83%;
  text-transform: capitalize;
  font-weight: 500;
}
.div14 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.div17,
.item {
  position: absolute;
  display: none;
}
.item {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div17 {
  top: 22.86%;
  left: 24.17%;
  text-transform: capitalize;
  font-weight: 500;
}
.div16 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.div19,
.inner {
  position: absolute;
  display: none;
}
.inner {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div19 {
  top: 22.86%;
  left: 34.17%;
  text-transform: capitalize;
  font-weight: 500;
}
.div18 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.div21,
.rectangle-div {
  position: absolute;
  display: none;
}
.rectangle-div {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div21 {
  top: 22.86%;
  left: 17.5%;
  text-transform: capitalize;
  font-weight: 500;
}
.div20 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.child1,
.div23 {
  position: absolute;
  display: none;
}
.child1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div23 {
  top: 22.86%;
  left: 25%;
  text-transform: capitalize;
  font-weight: 500;
}
.div22 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.child2,
.div25 {
  position: absolute;
  display: none;
}
.child2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div25 {
  top: 22.86%;
  left: 20.83%;
  text-transform: capitalize;
  font-weight: 500;
}
.div24 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.child3,
.div27 {
  position: absolute;
  display: none;
}
.child3 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div27 {
  top: 22.86%;
  left: 18.33%;
  text-transform: capitalize;
  font-weight: 500;
}
.div26 {
  width: 120px;
  height: 35px;
  position: relative;
  display: none;
}
.child4,
.child5 {
  width: 209px;
  height: 22.2px;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.child4,
.child5,
.div28,
.line-div {
  position: relative;
  display: none;
}
.div28 {
  width: 173px;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  white-space: pre-wrap;
  text-align: left;
}
.line-div {
  width: 15px;
  height: 1px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
}
.div30 {
  top: 0;
  font-weight: 500;
  color: var(--color-black);
  text-align: left;
}
.div30,
.mask-group-icon,
.uah {
  position: absolute;
  left: 0;
}
.uah {
  top: 76.97%;
  letter-spacing: -0.03em;
  font-weight: 500;
}
.mask-group-icon {
  height: 68.92%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 31.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div31 {
  position: absolute;
  top: 70.93%;
  left: -0.31%;
  font-size: var(--font-size-lg);
}
.component-17,
.uah1 {
  position: absolute;
  left: 0;
}
.component-17 {
  top: 64px;
  width: 327px;
  height: 547px;
}
.uah1 {
  top: 76.97%;
  letter-spacing: -0.03em;
  font-weight: 500;
}
.mask-group-icon1 {
  position: absolute;
  height: 68.92%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 31.08%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div32 {
  position: absolute;
  top: 70.93%;
  left: -0.31%;
  font-size: var(--font-size-lg);
}
.component-18 {
  position: absolute;
  top: 64px;
  left: 357px;
  width: 327px;
  height: 547px;
}
.div29 {
  width: 684px;
  height: 611px;
  display: none;
  max-width: 100%;
  font-size: var(--font-size-5xl);
}
.div29,
.div33 {
  color: black;
  position: relative;
}
.stock-status-child {
  height: 7.4px;
  width: 11px;
  position: relative;
}
.div34 {
  color: black;
  position: relative;
}
.stock-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;
  padding-right: 5px;
  gap: 0 6px;
  opacity: 0.5;
  text-align: center;
}

.icon1 {
  height: 3.2px;
  width: 9px;
  object-fit: contain;
}





.text-frame-small {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.div38 {
  position: relative;
  font-size: var(--desktop-hyperlink-medium-15-size);
  text-align: center;
}
.rectangle-frame {
  width: 1163px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.div39 {
  position: relative;
  font-weight: 500;
}
.line-segment {
  position: absolute;
  top: 6px;
  left: 0;
  border-top: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 260px;
  height: 1px;
}
.child6,
.child7 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-salmon);
  width: 11px;
  height: 11px;
  z-index: 1;
}
.child7 {
  left: 248px;
}
.div40 {
  align-self: stretch;
  height: 11px;
}
.div40,
.season-frame,
.season-frame1 {
  position: relative;
}
.empty-frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.empty-frame-wrapper,
.slider-frame {
  display: flex;
  justify-content: flex-start;
}
.empty-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-10xs) 0 var(--padding-8xs);
}
.slider-frame {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 9px 0;
}

.text-box-frame {
  width: 279px;
  flex-direction: column;
  gap: 30px 0;
}
.dropdown-frame {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
}
.div41 {
  position: relative;
  font-weight: 500;
}
.text-components {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.text-components1 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-2-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-salmon);
  box-sizing: border-box;
}
.div42 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-2xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-3-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div43 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-4-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div44 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-5-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div45 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-6-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div46 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-1-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div47 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-2-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div48 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-21 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-2xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-3-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0.4px;
  bottom: 0;
  left: -0.4px;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div49 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-31 {
  width: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-smi) var(--padding-7xs)
    var(--padding-5xs);
  box-sizing: border-box;
  position: relative;
}
.component-4-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div50 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-41 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-5-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div51 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-51 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.component-6-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.div52 {
  position: relative;
  font-weight: 500;
  z-index: 1;
}
.component-61 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-2xs) var(--padding-7xs)
    var(--padding-3xs);
  position: relative;
}
.rectangle-components {
  align-self: stretch;
  width: 262px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 4.8px;
}
.parent,
.rectangle-components,
.rectangle-components-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-components-wrapper {
  flex: 1;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  font-size: var(--desktop-hyperlink-medium-15-size);
}
.parent {
  height: 135px;
  flex-direction: column;
  gap: 30px 0;
}
.dropdown-frame1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
}

.rectangle-frame1,
.rectangle-frame2,
.rectangle-frame3 {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
}
.spring-summer-autumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px 0;
}
.p1,
.p2,
.p3 {
  margin: 0;
}
.div54 {
  position: relative;
  line-height: 40px;
}
.season-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 7px;
}


.text-components2 {
  flex-direction: column;
  gap: 20px 0;
}
.apply-button {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
}
.div55 {
  position: relative;
  font-weight: 500;
}
.similarto-spring-summer-autumn1 {
  width: 20px;
  height: 20px;
  position: relative;
  background-color:black;
}
.similarto-spring-summer-autumn2 {
 
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
 
  box-sizing: border-box;
}

.similarto-spring-summer-autumn3 {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border: 0.5px solid var(--color-silver-100);
  box-sizing: border-box;
  background-color:#fbd3b6;
}
.similarto-spring-summer-autumn4,
.similarto-spring-summer-autumn5 {
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #ead5c6;
}
.similarto-spring-summer-autumn5 {
  background-color: #bd9d8e;
}
.similarto-spring-summer-autumn6,
.similarto-spring-summer-autumn7 {
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #c28222;
}
.similarto-spring-summer-autumn7 {
  background-color: #5f7853;
}
.similarto-spring-summer-autumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px 0;
}
.p10,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9 {
  margin: 0;
}
.div56 {
  position: relative;
  line-height: 40px;
}
.rectangle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 7px;
}
.div57 {
  position: relative;
  font-size: var(--font-size-sm);
  text-decoration: underline;
  line-height: 293.7%;
}
.rectangle-parent {
  width: 143px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0;
}
.div58 {
  width: 2px;
  flex: 1;
  position: relative;
  border-right: 2px solid var(--color-silver-100);
  box-sizing: border-box;
}
.similarto-spring-summer-autumn8 {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xl) 0 0;
  box-sizing: border-box;
}
.frame-instance {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.line-frame1,
.tovares-group,
.u-a-h-component {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.u-a-h-component {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-lgi);
  font-size: var(--font-size-lg);
}
.line-frame1,
.tovares-group {
  flex-direction: column;
  max-width: 100%;
}
.tovares-group {
  width: 321px;
  gap: 20px 0;
}
.line-frame1 {
  align-self: stretch;
  gap: 33px 0;
}
.div59 {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
}

.button-frame,
.checkbox-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.checkbox-frame {
  align-self: stretch;
  gap: 35px 0;
}
.button-frame {
  width: 329px;
  padding: 38px 0 0;
  box-sizing: border-box;
}
.uah2 {
  width: 109px;
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 500;
  display: none;
}
.mask-group-icon2 {
  height: 377px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.div61 {
  width: 206px;
  position: relative;
  font-size: var(--font-size-lg);
  display: none;
}
.component-9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 170px;
  box-sizing: border-box;
  max-width: 100%;
}
.icon3 {
  width: 24px;
  height: 15.2px;
  position: absolute;
  margin: 0 !important;
  right: 4px;
  bottom: 136.8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-frame4 {
  left: 0;
  background-color: var(--color-silver-100);
}
.rectangle-frame4,
.rectangle-frame5,
.rectangle-frame6 {
  width: 107.3px;
  height: 2px;
  position: absolute;
  margin: 0 !important;
  bottom: 163px;
  z-index: 1;
}
.rectangle-frame5 {
  left: calc(50% - 53.6px);
  background-color: var(--color-silver-100);
}
.rectangle-frame6 {
  right: 0;
  background-color: var(--color-gray-100);
}
.product-instance {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
}
.div62 {
  position: relative;
  font-size: var(--desktop-hyperlink-medium-15-size);
  text-transform: uppercase;
  font-weight: 500;
  font-family: var(--desktop-hyperlink-medium-15);
  color: var(--background-white);
  text-align: center;
}
.recycle-bin-icon {
  cursor: pointer;
  border: 0;
  padding: var(--padding-3xs) 49px var(--padding-2xs) var(--padding-31xl);
  background-color: var(--color-gray-100);
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -38px;
}
.recycle-bin-icon:hover {
  background-color: #595959;
}
.product-frame,
.products-frame {
  width: 327px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.products-frame {
  width: 1042px;
  height: 549px;
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 0 30px;
  text-align: center;
}
.p11,
.p12 {
  margin: 0;
}
.div63,
.uah3 {
  height: 12.7px;
  position: absolute;
  margin: 0 !important;
  left: 352px;
  z-index: 1;
}
.div63 {
  width: 213px;
  top: 396.3px;
  font-size: var(--font-size-lg);
  line-height: 105%;
  display: inline-block;
}
.uah3 {
  width: 126px;
  top: 430.5px;
  letter-spacing: -0.03em;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.recycle-2-1-icon {
  width: 25px;
  height: 25px;
  position: relative;
  object-fit: cover;
  z-index: 1;
  margin-top: -25px;
}
.button-frame1,
.component-11-parent {
  height: 547px;
  width: 327px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.component-11-parent {
  width: 1042px;
  height: 1124px;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px 25.5px;
}
.div60,
.frame-with-color-swatches,
.text-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.div60 {
  flex: 1;
  overflow-x: auto;
  flex-direction: column;
  position: relative;
  gap: 28px;
  min-width: 729px;
  font-size: var(--font-size-5xl);
}
.frame-with-color-swatches,
.text-frame {
  align-self: stretch;
}
.frame-with-color-swatches {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 29px;
  color: var(--color-gray-100);
}
.text-frame {
  flex-direction: column;
  gap: 12px 0;
}
.p13 {
  margin: 0;
}


.main-content-container {
  width: 524px;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-lg);
  color: red;
}
.line-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--desktop-subtitle-medium-20-size);
  color: var(--color-darkgray-100);
}
.quantity-input,
.text-container {
  align-items: flex-start;
  max-width: 100%;
}
.text-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 53px 0;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
}
.quantity-input {
  width: 1440px;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 5px 0;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkgray-100);
  font-family: var(--desktop-hyperlink-medium-15);
}
.div13,
.main-title,
.quantity-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-title {
  align-self: stretch;
  align-items: center;
  gap: 20px 0;
  max-width: 100%;
}
.h25 {
  margin-top: 35px;
  margin-bottom: 35px;
  width: 102px;
  position: relative;
  font-size: var(--desktop-h2-bold-28-uppercase-size);

  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  color: var(--color-black);
  justify-content: center;
}
#filter_mob
  {
    display: none;
  }
  /* Контейнер для карточек */
.cards-container_ {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr)); /* 2 карточки в строку */
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

/* Стили для карточек */
.cart-item_ {
  background-color: white;
  border-radius: none;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Занимает всю высоту родительского элемента */
  padding: 20px; /* Задает отступы вокруг спиннера */
}
.containerCart {
  display: grid;
  gap: 5px; /* Отступы между карточками */
  grid-template-columns: repeat(2, 1fr); /* Две карточки в строку */
  margin: 0; /* Убираем внешние отступы контейнера */
  padding: 0; /* Убираем внутренние отступы контейнера */
  padding-right: 5px;
  padding-left: 5px;
  width: 100%; /* Занимает всю ширину родителя */
}
@media (min-width: 1024px) {
  .containerCart {
    grid-template-columns: repeat(4, 1fr); /* 4 карточки в строку */
    gap: 20px; /* Увеличиваем расстояние между карточками */
    padding: 20px; /* Увеличиваем отступы */
  }
 
}
@media screen and (max-width: 1125px) {
  .stock-status
  {
    padding-left: 25px;
  }
  .showmoreBtn
  {
    text-align: center !important;
    margin-top: 15px;
  }
  #filter_mob
  {
    display: block;
  }
  #filters {
    display: none;
  }
  .column-hide{
    display: none;
  }
  
}
@media screen and (max-width: 825px) {
  .column-hide{
    display: none;
  }
  .component-9 {
    padding-bottom: 110px;
    box-sizing: border-box;
  }
  .div60 {
    min-width: 100%;
  }
  .text-container {
    gap: 53px 0;
  }
  .div13 {
    gap: 240px 0;
  }
}
@media screen and (max-width: 450px) {
  .div30,
  .h23,
  .uah,
  .uah1 {
    font-size: var(--font-size-lgi);
  }
  .div37 {
    font-size: var(--font-size-base);
  }
  .rectangle-frame {
    flex-wrap: wrap;
  }
  .div39,
  .div41,
  .div53,
  .div55 {
    font-size: var(--font-size-base);
  }
  .line-frame1 {
    gap: 33px 0;
  }
  .checkbox-frame {
    gap: 35px 0;
  }
  .uah2,
  .uah3 {
    font-size: var(--font-size-lgi);
  }
  .div13 {
    gap: 240px 0;
  }
}
