.ticker-wrapper {
  position: relative;
  overflow: hidden;
}


/* Контейнер для снежинок */
.snowflakes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Растягиваем на всю высоту экрана */
  pointer-events: none;
  z-index: 1;
}
a
{color: #000;}

.snowflake {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.8;
  animation: fall linear infinite;
}

/* Анимация падения снежинок */
@keyframes fall {
  0% {
    transform: translateY(-100px) scale(0.5);
  }
  100% {
    transform: translateY(100vh) scale(1);
  }
}

.snowflake:nth-child(1) {
  width: 9px;
  height: 9px;
  left: 10%;
  animation-duration: 4s;  /* Увеличена продолжительность */
  animation-delay: 0s;
}

.snowflake:nth-child(2) {
  width: 8px;
  height: 8px;
  left: 30%;
  animation-duration: 5s;  /* Увеличена продолжительность */
  animation-delay: 1s;
}

.snowflake:nth-child(3) {
  width: 6px;
  height: 6px;
  left: 50%;
  animation-duration: 4.5s;  /* Увеличена продолжительность */
  animation-delay: 2s;
}

.snowflake:nth-child(4) {
  width: 9px;
  height: 9px;
  left: 70%;
  animation-duration: 6s;  /* Увеличена продолжительность */
  animation-delay: 0.5s;
}

.snowflake:nth-child(5) {
  width: 4px;
  height:4px;
  left: 80%;
  animation-duration: 5s;  /* Увеличена продолжительность */
  animation-delay: 3s;
}

.ticker-wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: #000;
  padding: 15px 20px;
  color: #ffffff;
  text-transform: uppercase;
  box-sizing: border-box;
}
.ticker {
  display: inline-block;
  animation: marquee 30s linear infinite;
}
.ticker div {
  display: inline-block;
  padding-right: 50px;
}
@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
}
.headermob
    {
      display: none;;
    }
.text1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-100);
  box-sizing: border-box;
}
.div97,
.ru {
  flex: 1;
  position: relative;
}

.div97 {
  flex: 1;
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; 
  padding: var(--padding-5xs);
  gap: var(--gap-8xs);
  z-index: 1;
}
.svg-container {
  display: flex;
  align-items: center;
}
.btn{
  border-radius: 0%;
}
.form-select::after,
.dropdown-toggle::after {
  display: none;
}
#nav-dropdown-dark-example::after {
  content: none; 
}
.bi-search {
  margin-top: 5px; 
}
.btn {
  --bs-btn-padding-x: 0.2rem;
}
.header-container {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .px-main-page {
    width: 100%;
    background-color: var(--typography-white);
    overflow: hidden;
    box-sizing: border-box;
    letter-spacing: normal;
  }
  .logo-menu-navigation {
    display: flex;
    align-items: center;
  }
  
  .menu-navigation {
    display: flex;
  }
  
  .search-field-icons {
    display: flex;
    align-items: center;
  }
 .likes,
  .button-nav4 {
  color:hotpink;
  }
.likes
{
  margin-left: -5px;
  margin-top: -40px;
  align-items:center;
  position: relative;
  text-transform: uppercase;
  font-weight: 100;

}
  .button-nav,
  .button-nav1,
  .button-nav2,
  .button-nav3,
  .button-nav4,
  .button-nav5
   {
    color: #000;
    position: relative;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 15px; 
  }
  
  .icons {
    display: flex;
    align-items: center;
    margin-left: 15px; 
  }
  
  .shopping-bag-1 {
    display: flex;
    align-items: center;
  }
  
  .pagination-element-frame {
    margin-left: 5px; 
  }
  
  .heading-frame {
    height: 30px;
  width: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
:link
{
  color: #000;
}

  .favourite-icon,
.language-icon,
.pagination-element-frame,
.user-icon {
  margin-left: 5px;
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.navbar-toggler {
  border: none;
}
.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
}

.accordion-button:focus {
    z-index: 3;
    border-color:  black;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.item-title
{
  width: 100%;
    text-transform: uppercase;
    margin-top: 10px;
    border: none;
}
.dropdown-menu {
  border: none;
}
.currency-list {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  cursor: pointer;
}
.dropdown-menu.show {
  z-index: 10000;
}
.currency-list p {
  margin: 0;
  padding: 8px;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s;
}

.currency-list p:hover {
  background-color: #3f3d3d;
}
.logo-icon {
    width: 46.8px;
    max-height: 100%;
    object-fit: cover;
    min-height: 40px;
    margin-right: 50px;
  }
 :link
  {
   
    text-decoration: none;

  }
 
  @media (min-width: 576px) {
    .card-group {
     
      flex-wrap: nowrap;
    }
    #videocontent
  {
    display: none;
  }
  }
  @media screen and (max-width: 1024px) {
    #price{
      font-size: 15px;
  }
 
  }

  @media screen and (max-width: 750px) {
  #carousel-image {
      /* height: 450px;  */
     height: 260px;
      object-fit: cover; 
      object-position: center; 
      
    }
    #titleRow
{
  display: none;
}
.logo-icon
{
  
    width: 58.8px;
    max-height: 100%;
    object-fit: cover;
    min-height: 40px;
    margin-left: 60px;
  
}
    .header
    {
      display: none; 
    }
    .headermob
    {
      display:block;
      
    }
  }